import "./App.css";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaGithub,
  FaTelegram,
  FaLinkedinIn,
} from "react-icons/fa";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { ImBlog } from "react-icons/im";

import Infomation from "./components/Infomation";
import Avatar from "./components/Avatar";
import avatar from "./assets/sayed.jpg";

const infomations = {
  name: "Abu Sayed",
  socials: [
    {
      name: "Facebook",
      url: "https://facebook.com/abusayed0206",
      icon: <FaFacebook />,
    },
    {
      name: "Twitter",
      url: "https://twitter.com/abusayed0206",
      icon: <FaTwitter />,
    },
    {
      name: "Github",
      url: "https://github.com/abusayed0206",
      icon: <FaGithub />,
    },
    {
      name: "Telegram",
      url: "https://abusayed0206.t.me",
      icon: <FaTelegram />,
    },
    {
      name: "Instagram",
      url: "https://instagram.com/abusayed0206",
      icon: <FaInstagram />,
    },
    {
      name: "LinkedIn",
      url: "https://linkedin.com/in/abusayed0206",
      icon: <FaLinkedinIn />,
    },
    {
      name: "Blog",
      url: "https://blog.abusayed.dev",
      icon: <ImBlog />,
    },
    {
      name: "Email",
      url: "mailto:hi@abusayed.dev?subject=Contact with Sayed &body=I want to contact with you,Sayed. &cc=lrs@hi2.in",
      icon: <MdOutlineAlternateEmail />,
    },
  ],
  paragraph: "Negative by Blood🩸 Positive by Heart❤️",
};

function App() {
  return (
    <div className="max-w-4xl mx-auto min-h-screen flex flex-col md:flex-row justify-center items-center">
      <Avatar avatar={avatar} />
      <Infomation infomations={infomations} />
    </div>
  );
}

export default App;
